import React, { useState, useEffect } from 'react'
import background from "../images/products.png";
import { Dropdown, DropdownButton, Pagination, Modal, Spinner } from 'react-bootstrap';
import { useForm } from '../hooks/useForm';
import { db, firebase, storage } from '../firebase';
import { AiFillDelete } from 'react-icons/ai';

export const Shop = () => {
    
    const limit = 8;
    const auth = firebase.auth();
    const [categorias, setcategorias] = useState([])
    const [products, setproducts] = useState([])
    const [productsData, setproductsData] = useState([])
    const [show, setShow] = useState(false);
    const [values, handleInputChange] = useForm({ name: '', reference: '', categoryId:'' })
    const { name, reference, categoryId } = values;
    const [loading, setloading] = useState(false)
    const [category, setcategory] = useState({id:'',data:{name:''}})
    const [page, setpage] = useState(1)
    const [imageshow, setimageshow] = useState('')
    const [user, setuser] = useState({email:''})
    const [catmodal, setcatmodal] = useState('Selecciona la categoria')
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let active = 2;
    let items = [];

    const [image, setImage] = useState(null);

    const handleChange = e => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    const handleUpload = async() => {
        if (name && reference && image) {
            setloading(true)
            let imgPath = `/images/${image.name}`;
            const uploadTask = storage.ref(imgPath).put(image);
            uploadTask.on(
                "state_changed",
                snapshot => {
                },
                error => {
                    console.log(error);
                },
                () => {
                    storage.ref("images")
                    .child(image.name)
                    .getDownloadURL()
                    .then(url => {
                        console.log('URL ', url);
                        createProduct(url, imgPath);
                        handleClose();
                    });
                }
            );
        }
    };

    const removeProduct = (item)=>{
        setloading(true)
        var desertRef = storage.ref(item.data.path);
        db.collection("productos").doc(item.id).delete().then(() => {
            console.log("Doc deleted!");
            desertRef.delete().then(() => {
                console.log("Image deleted!");
                setloading(false)
            }).catch((error) => {
                console.error("Error removing Image: ", error);
            });  
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
           
    }

    const createProduct = (url, path) => {
        db.collection("productos").add({
            name, reference, url, path, categoryId
        }).then(sucess => {
            console.log(sucess);
            setloading(false)
        }).catch(err => console.log(err));;
    }

    const getCategories =() =>{
        db.collection("cats").onSnapshot((snapshot) => {
            let myArr = snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));
            setcategorias(myArr);
            if(myArr.length > 0) {
                setcategory(myArr[0])
                getProducts(myArr[0].id)
                handleInputChange({ target:{ value:myArr[0].id, name:'categoryId' } })
            }
        });
    }
    const getProducts =(categoryId) =>{
        
        db.collection("productos").where("categoryId", "==", categoryId).onSnapshot((snapshot) => {
            let myArr = snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));
            let arrFinal = []
            for (let index = 0; index < (myArr.length/limit); index++) {
                arrFinal.push(myArr.slice(0+(index*limit),limit+(limit*index) ))
            }
            setproducts(arrFinal);
            setproductsData(arrFinal.length > 0 ? arrFinal[0] : [])
        });
    }

    useEffect(() => {

        let isMounted = true;               // note mutable flag
        if (isMounted){
            console.log(auth.currentUser);
            if(auth.currentUser) setuser(auth.currentUser)
            getCategories()
        }
        return () => { isMounted = false };

    }, []);

    const selectEvent = e =>{
        let cats = categorias;
        let cat = cats.find(ele => ele.id == e);
        setcategory(cat)
        getProducts(cat.id)
    }

    const setCurrentPage = (index)=>{
        let productsLocal = products;
        setpage(index+1)
        setproductsData(productsLocal[index])
    }

    const search = e => {
        console.log(e.target.value);
        let myProduts = [];
        products.forEach(arr => myProduts.push(...arr))
        let regex = new RegExp(e.target.value, 'i');
        console.log(myProduts);
        let newProducts = myProduts.filter(e => e.data.name.match(regex) )
        setproductsData(newProducts.length > limit ? newProducts.slice(0, limit): newProducts)
    }

    const selectCategoryModal = e =>{
        handleInputChange({ target:{ value:e, name:'categoryId' } })
        let cat = categorias.find(ele => ele.id == e)
        console.log('cat ', cat, e, categorias);
        setcatmodal(cat.data.name)
    }

    return (
        <div>
            
            <div className="fondo" style={{ backgroundImage: `url(${background})` }}>
                <div className="fs-1 text-center text-white pt-5">
                    {category.data.name}
                </div>
                <div className="fs-3 text-center text-white pb-5">
                    Productos
                </div>
            </div>

            <div className="container my-4">
                <div className="row">
                    <div className="col-12 col-md-3 bg-dark">
                        <div className="mb-3">
                            <label className="form-label">Selecciona categoria</label>
                            <DropdownButton onSelect={selectEvent} title={category.data.name.length > 18 ? category.data.name.substr(0,18) + '...' : category.data.name }>
                                { categorias.map((ele, index) => <Dropdown.Item eventKey={ele.id}  key={index + '_categoria'}>{ele.data.name}</Dropdown.Item>) }
                            </DropdownButton>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Buscar por palabra</label>
                            <input onChange={search} type="text" className="form-control" />
                        </div>
                        {
                            user.email == 'gerencia@jmgases.co' ?
                            <div className="btn btn-primary btn-block mb-3" onClick={handleShow}>Crear producto</div>
                            :null
                        }
                    </div>
                    <div className="col-12 col-md-9">
                        <div className="row">
                            {
                                productsData.length == 0 ?
                                <div class="alert alert-primary" role="alert">
                                    No hay productos disponibles!
                                </div>
                                :null
                            }
                            {productsData.map((ele, index) => {
                                return (
                                    <div key={index + '_product'} className="col-6 col-md-4 col-lg-3">
                                        <div className="card mb-3">
                                            <img 
                                                onClick={()=>setimageshow(ele.data.url)} 
                                                src={ele.data.url} style={{ cursor:'pointer' }} 
                                                className="card-img-top" alt="..." />
                                            <div className="card-text mx-2 my-1">{ele.data.name}</div>
                                            <div className="card-title text-muted mx-2 mb-1">REF {ele.data.reference}</div>
                                            {
                                                user.email == 'gerencia@jmgases.co' ?
                                                <div className="btn btn-danger btn-sm" onClick={()=> removeProduct(ele)}>
                                                    <AiFillDelete/>
                                                </div>
                                                :null
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="btn-group" role="group" aria-label="Basic example">
                            {
                                products.map((ele,index)=><button 
                                    key={index+'_pag'} 
                                    type="button" 
                                    onClick={()=> setCurrentPage(index)}
                                    className={'btn btn-primary' + (page == index +1 ? ' active':'')} >{index+1}</button>)
                            }
                            
                        </div>

                    </div>


                </div>

            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo producto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type="name"
                        placeholder="Nombre"
                        className="form-control mb-3"
                        autoComplete="off"
                        name="name"
                        value={name}
                        onChange={handleInputChange}
                    />
                    <input
                        type="reference"
                        placeholder="Referencia"
                        className="form-control mb-3"
                        autoComplete="off"
                        name="reference"
                        value={reference}
                        onChange={handleInputChange}
                    />
                    <input type="file" onChange={handleChange} className="form-control mb-3" />
                    <DropdownButton onSelect={selectCategoryModal} title={catmodal.length > 40 ? catmodal.substr(0,40) + '...' : catmodal }>
                        { categorias.map((ele, index) => <Dropdown.Item eventKey={ele.id}  key={index + '_categoria'}>{ele.data.name}</Dropdown.Item>) }
                    </DropdownButton>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn btn-primary" onClick={handleClose}>
                        Cancelar
                    </div>
                    <div className="btn btn-primary" onClick={handleUpload}>
                        Guardar
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={loading}>
                <Modal.Body>
                    <div className="fs-1 text-center text-primary">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <span className="ms-4">
                            Cargando...
                        </span>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={imageshow} onHide={() => setimageshow('')}>
                <Modal.Body>
                    <div className="fs-1 text-center text-primary">
                        <div className="row">
                            <div className="col-12">
                                <img src={imageshow} alt="" height="500" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
