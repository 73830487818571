import React, { useState, useEffect } from 'react'
import { 
    Carousel
} from 'react-bootstrap';
import { db } from '../firebase';

export const Slider = () => {

    const [banners, setbanners] = useState([])

    useEffect(() => {
        db.collection("categorias").onSnapshot((snapshot) => {
            setbanners(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
        console.log( 'Banners '+banners );
    }, []);

    return (
        <div>
            <Carousel>
                {
                    banners.map((ele, index)=>{
                        return(
                            <Carousel.Item key={index + '_banner'}>
                                <img
                                className="d-block w-100"
                                src={ele.data.image}
                                alt="First slide"
                                />
                                <Carousel.Caption>
                                    <div className="containerbanner">
                                        <div className="titlebanner">
                                            {ele.data.name}
                                        </div>
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}
