import React from 'react'
import { AiOutlineCheckCircle, AiOutlineHistory, AiOutlineSafety } from 'react-icons/ai'
import { Slider } from '../components/Slider'

export const Home = () => {

    const services = [
        { title:'Maquinaria amarilla', img:'assets/services6.jpeg' },
        { title:'Movimiento de tierras', img:'assets/services7.jpeg' },
        { title:'Plantas electricas', img:'assets/services8.jpeg' },
        { title:'Compresores de aire', img:'assets/services9.jpeg' },
        { title:'Mantenimiento de tuberias', img:'assets/services4.jpeg' },
        { title:'Servicio de torno', img:'assets/services5.jpeg' },
        { title:'Alquiler de equipos', img:'assets/services3.jpeg' },
        { title:'Servicios de mecánica', img:'assets/service2.jpeg' },
        { title:'Contratación de personal', img:'assets/service1.jpeg' },

    ]

    return (
        <div>
            <Slider></Slider>
            <div className="container">
                <div className="text-center fs-2 mt-5 mb-2 text-primary">Servicios Especiales</div>
                <hr/>
                <div className="row my-4">

                    {
                        services.map((ele,index)=>{
                            return(
                                <div className="col-md-4 col-sm-6 col-12">
                                    <div className="card m-3">
                                        <div className="row" style={{ backgroundImage: ` url("${ele.img}")`, 
                                        backgroundSize:'cover', height:'170px', backgroundPosition:'center center' }}>
                                            <div className="col-12 mt-3">
                                                <div className="fs-4 text-light mt-5 mb-3 py-2 px-1 text-center" style={{ color:'white',
                                            backgroundImage: `linear-gradient( rgba(0, 30, 54, 0.8), rgba(0, 30, 54, 0.8))` }}>{ele.title}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                </div>
                <div className="text-center fs-2 mt-5 mb-2 text-primary">Sobre Nosotros</div>
                <hr/>
                <div className="row my-4">
                    <div className="col-12 col-md-4">
                        <div className="card mb-3">
                            <img src="assets/1.jpeg" className="card-img-top" alt="..." height="350"/>
                            <div className="fs-2 text-primary mx-2 my-1">Quienes somos</div>
                            <div className="card-title text-muted mx-2 mb-1">
                            Somos una empresa llanera dedicada a prestar productos y servicios de calidad para la industria agrícola, petrolera entre otras, nuestro objetivo es contribuir al crecimiento de las operaciones de nuestros clientes en diferentes líneas de suministro.
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="card mb-3">
                            <img src="assets/2.jpeg" className="card-img-top" alt="..." height="350"/>
                            <div className="fs-2 text-primary mx-2 my-1">Misión</div>
                            <div className="card-title text-muted mx-2 mb-1">
                            Facilitar la operación de nuestros clientes, entregando en sus instalaciones o en nuestros puntos de venta, el suministro de gases industriales comprimidos y materiales de ferretería con calidad y oportunidad.
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="card mb-3">
                            <img src="assets/3.jpeg" className="card-img-top" alt="..." height="350"/>
                            <div className="fs-2 text-primary mx-2 my-1">Visión</div>
                            <div className="card-title text-muted mx-2 mb-1">
                            Ser la empresa líder y preferida en la región, por dar acceso a los gases industriales de la más alta calidad, y la diversidad de suministros y soluciones de ferretería, excediendo siempre las expectativas de nuestros clientes.
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
