import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBQGJKtWLqurGhFYRVYTSj7gqz9s5StNyg",
    authDomain: "jmgases-9cf5c.firebaseapp.com",
    projectId: "jmgases-9cf5c",
    storageBucket: "jmgases-9cf5c.appspot.com",
    messagingSenderId: "1017674895426",
    appId: "1:1017674895426:web:b9910c3ea7e9ab36879ddb"
};
  
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const storage = firebase.storage();

export {
    db,
    firebase,
    storage
}