import logo from './logo.png';
import './App.scss';
import { AiOutlineWhatsApp, AiFillPhone, AiOutlineLogin, AiTwotoneFire , AiFillShopping} from "react-icons/ai";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Home } from './pages/Home';
import { Shop } from './pages/Shop';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from './hooks/useForm';
import { firebase } from './firebase';
import { Footer } from './components/Footer';


function App() {
  const auth = firebase.auth();
  if(auth.currentUser){
    if(!auth.currentUser.email)auth.signInAnonymously()
  } else {
    auth.signInAnonymously()
  }
  const [ values, handleInputChange ] = useForm({ email:'', password:''})
  const { email, password } = values;
  const [show, setShow] = useState(false);
  const [error, seterror] = useState('');

  const handleClose = () => {
    console.log(values);
    login(email,password);
  };
  const handleShow = () => setShow(true);

  const login = async ( email, password) => {
    try {
      const result = await auth.signInWithEmailAndPassword(email, password)
      console.log(result);
      setShow(false);
      seterror('')
    } catch (error) {
      seterror('Problemas al ingresar')
      console.log(error);
    }
  }

  

  return (
    <Router>
<div className="cover-spin"></div>
      <div className="container">
        <div className="row my-4 justify-content-center">
          <div className="col-12 col-md-auto">
            <img
              src={logo}
              width="200"
              height="50"
              className="d-inline-block align-top"
              alt="JMGASES"
            />
          </div>
          <div className="col">
            <div className="row align-items-center justify-content-center">
              <div className="col-auto d-none d-lg-block">
                <Link to="/">
                  <div className="btn btn-outline-primary btn-lg">
                    <AiTwotoneFire/>  Servicios
                  </div>
                </Link>
              </div>
              <div className="col-auto d-none d-lg-block">
                <Link to="/productos">
                  <div className="btn btn-outline-primary btn-lg">
                    <AiFillShopping/> Productos
                  </div>
                </Link>
              </div>
              <div className="col"></div>
              <div className="col-auto mt-2 d-none d-xl-block">
                <a href="tel:3213427404" className="nolink">
                  <span className="item_menu"><AiFillPhone/> 3213427404 </span> 
                </a>
              </div>
              <div className="col-auto d-block d-xl-none ">
                <a href="tel:3213427404">
                  <span className="item_menu_icon"> <AiFillPhone/> </span>
                </a>
              </div>
              <div className="col-auto d-block d-lg-none ">
                <Link to="/">
                  <span className="item_menu_icon"> <AiTwotoneFire/> </span>
                </Link>
              </div>
              <div className="col-auto d-block d-lg-none ">
                <Link to="/productos">
                  <span className="item_menu_icon"> <AiFillShopping/> </span>
                </Link>
              </div>
              <div className="col-auto">
                <a target="_blank" href="https://api.whatsapp.com/send?phone=573213427404&amp;text=Hola,%20JMGases%20requiero%20informacion">
                  <span className="item_menu_icon"> <AiOutlineWhatsApp/> </span>
                </a>
              </div>
              <div className="col-auto" onClick={handleShow}>
                <span className="item_menu_icon"> <AiOutlineLogin/>  </span> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <Switch>
          <Route path="/productos">
            <Shop />
          </Route>
          <Route path="/">
            <Home />
          </Route>
          <Route path="*">
            <Home />
          </Route>
        </Switch>
        <Footer></Footer>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Inicio de sesion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-3">
                <label className="form-label">Usuario</label>
                <input 
                    type="email"
                    placeholder="Correo electronico"
                    className="form-control"
                    autoComplete="off"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Contraseña</label>
                <input 
                    type="password"
                    placeholder="Contraseña"
                    className="form-control"
                    autoComplete="off"
                    name="password"
                    value={password}
                    onChange={handleInputChange}
                />
              </div>
              {
                error ?
                <div className="alert alert-primary" role="alert">
                 {error}
                </div>
                :null
              }
            </Modal.Body>
            <Modal.Footer>
                <div className="btn btn-primary" onClick={handleClose}>
                    Ingresar
                </div>
            </Modal.Footer>
        </Modal>
    </Router>
  );
}

export default App;
