import React from 'react'

export const Footer = () => {
    return (
        <div className="bg-primary">
            <div className="container">
                <div className="row py-5">
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <div className="h5" style={{ color:'white' }}>CONTACTO</div>
                        <div style={{ color:'white' }}>
                            Correo gerencia@jmservices.com.co <br/>
                            Telefonos 3213427404
                        </div>
                        <br/>
                        <div className="h5" style={{ color:'white' }}>SUCURSAL PUERTO GAITAN</div>
                        <div style={{ color:'white' }}>
                            Carrera 10 #13D 32B Barrio Galan
                        </div>
                        <br/>
                        <div className="h5" style={{ color:'white' }}>SUCURSAL VILLAVICENCIO</div>
                        <div style={{ color:'white' }}>
                            Calle 17 # 33 40 Barrio La Florida
                        </div>

                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <div className="h5" style={{ color:'white' }}>MAPA PUERTO GAITAN</div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d715.6034587417281!2d-72.08423497397581!3d4.3127907856348555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e1484f0324effd3%3A0x270cfee26847bd10!2sPuerto%20Gait%C3%A1n%2C%20Meta!5e0!3m2!1ses!2sco!4v1639243715529!5m2!1ses!2sco" width="auto" height="200" loading="lazy"></iframe>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <div className="h5" style={{ color:'white' }}>MAPA VILLAVICENCIO</div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d433.03774722051344!2d-73.62715156454834!3d4.133998324303889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3e2e73acfbcb0f%3A0x3b09000a2d578d2c!2sCl.%2017%20%2333-40%2C%20Villavicencio%2C%20Meta!5e0!3m2!1ses!2sco!4v1639243391499!5m2!1ses!2sco" width="auto" height="200" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}
